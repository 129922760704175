export const FEATURES = {
    NEW: 'NEW',
    HOT: 'HOT',
    BEST_FAVORITE: 'BEST_FAVORITE',
    BEST_VIEW: 'BEST_VIEW'
}

export const STATUSES = {
    BUY_NOW: 'BUY_NOW',
    ON_AUCTION: 'ON_AUCTION',
    OFFER: 'OFFER',
}

export const TYPES = {
    SIGNLE: 'SIGNLLE',
    BUNDLES: 'BUNDLES'
}

export const CURRENCIES = {
    BNB: 'BNB',
    ETH: 'ETH',
    MNFT: '$MNFT'
}

export const Collections = [
    {
        id: 261,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/BlackBeamin.m4a",
        name: "Black Beamin",
        price: '2.6',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/patJunior/background.jpg",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [FEATURES.NEW],
        likes: 34,
        createdAt: "2022-06-15 17:00:00",
        videoSrc: null,
    },
    {
        id: 72,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #1",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 73,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '1',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #2",
        features: [],
        likes: 98,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 74,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #3",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 75,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #4",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 76,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #5",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 77,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #6",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 78,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #7",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 79,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #8",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 80,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #9",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 81,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #10",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 82,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #11",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 83,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #12",
        features: [],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 84,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/drip.wav",
        name: "Drip",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "https://img.seadn.io/files/6bf5f69e016dc87d3d08f7aee3fbec4c.jpg?auto=format&fit=max&w=384",
        authorId: 12,
        authorName: "Drip #13",
        features: [FEATURES.NEW],
        likes: 100,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 131,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/imanEurope/CloseFriends.m4a",
        name: "Close friends",
        price: '1.2',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/imanEurope/background3.jfif",
        authorId: 13,
        authorName: "Drip #13",
        features: [FEATURES.NEW],
        likes: 66,
        createdAt: "2022-07-20 17:00:00",
        videoSrc: null
    },
    {
        id: 132,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/imanEurope/Commitment.m4a",
        name: "Commitment",
        price: '1',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/imanEurope/background2.jfif",
        authorId: 13,
        authorName: "Drip #13",
        features: [],
        likes: 66,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 133,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/imanEurope/Consent.m4a",
        name: "Consent",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/imanEurope/background1.jfif",
        authorId: 13,
        authorName: "Drip #13",
        features: [],
        likes: 34,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 134,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/imanEurope/Crush.m4a",
        name: "Crush",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/imanEurope/background2.jfif",
        authorId: 13,
        authorName: "Drip #13",
        features: [],
        likes: 34,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 135,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/imanEurope/Decide.m4a",
        name: "Decide",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/imanEurope/background3.jfif",
        authorId: 13,
        authorName: "Drip #13",
        features: [],
        likes: 34,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 136,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/imanEurope/LALovechild.m4a",
        name: "LA lovechild",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/imanEurope/laLovechild.jfif",
        authorId: 13,
        authorName: "Drip #13",
        features: [],
        likes: 34,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        id: 137,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/imanEurope/Patience.m4a",
        name: "Patience",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/imanEurope/background1.jfif",
        authorId: 13,
        authorName: "Drip #13",
        features: [],
        likes: 34,
        createdAt: "2022-07-16 17:00:00",
        videoSrc: null
    },
    {
        src: "/audio/apeDropsEth/FoolinYaself.mp3",
        id: 1,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        name: "Foolin Yaself",
        price: '0.1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/FoolinYaself.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [FEATURES.HOT, FEATURES.BEST_FAVORITE],
        likes: 110,
        createdAt: "2022-06-03 17:00:00",
        videoSrc: null
    },
    {
        src: "/audio/apeDropsEth/DontTextDontCall.mp3",
        id: 2,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        name: "Don't text don't call.",
        price: '0.06',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/DontTextDontCall.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [FEATURES.HOT, FEATURES.BEST_FAVORITE],
        likes: 24,
        createdAt: "2022-06-03 17:00:00",
        videoSrc: null
    },
    {
        src: "/audio/apeDropsEth/BeenSmokin.mp3",
        id: 3,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        name: "Been smookin",
        price: '0.05',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/BeenSmookin.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [FEATURES.HOT, FEATURES.BEST_FAVORITE],
        likes: 47,
        createdAt: "2022-06-05 17:00:00",
        videoSrc: null
    },
    {
        src: "/audio/apeDropsEth/Lit.mp3",
        id: 4,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        name: "Lit",
        price: '0.05',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/Lit.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [FEATURES.HOT],
        likes: 56,
        createdAt: "2022-06-06 17:00:00",
        videoSrc: null
    },
    {
        src: "/audio/apeDropsEth/Rollin.mp3",
        id: 5,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        name: "Rollin",
        price: '0.05',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/Rollin.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [],
        likes: 14,
        createdAt: "2022-06-06 17:00:00",
        videoSrc: null
    },
    {
        src: "/audio/apeDropsEth/TakeItFromAG.mp3",
        id: 6,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        name: "Take it from A G",
        price: '0.05',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/Rollin.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [],
        likes: 19,
        createdAt: "2022-06-07 17:00:00",
        videoSrc: null
    },
    {
        src: "/audio/apeDropsEth/Temperature.mp3",
        id: 7,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        name: "Temperature",
        price: '0.1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/temperature.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [],
        likes: 16,
        createdAt: "2022-06-07 17:00:00",
        videoSrc: null
    },
    {
        id: 8,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeDropsEth/WestCoastRider.mp3",
        name: "West coast rider",
        price: '0.1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/WestCoastRider.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [],
        likes: 45,
        createdAt: "2022-06-07 17:00:00",
        videoSrc: null
    },
    {
        id: 9,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "",
        name: "West coast rider",
        price: '0.1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/TheGreat.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [],
        likes: 45,
        createdAt: "2022-06-07 17:00:00",
        videoSrc: "/videos/apeDropsEth/TheGreat.mp4"
    },
    {
        id: 10,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "",
        name: "My Ape",
        price: '1.19',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeDrops/MyApe.png",
        authorId: 1,
        authorName: "Ape drops",
        features: [FEATURES.NEW],
        likes: 67,
        createdAt: "2022-06-07 17:00:00",
        videoSrc: "/videos/apeDropsEth/MyApe.mp4"
    },
    {
        id: 11,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/cryptoPunk/FestFeel.mp3",
        name: "Fest feel",
        price: '0.96',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/cryptoPunk/FestFeel.jpg",
        authorId: 2,
        authorName: "EDM punk",
        features: [],
        likes: 45,
        createdAt: "2022-06-08 17:00:00",
        videoSrc: null
    },
    {
        id: 12,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/cryptoPunk/CatWalk.mp3",
        name: "Catwalk",
        price: '0.96',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/cryptoPunk/CatWalk.jpg",
        authorId: 2,
        authorName: "EDM punk",
        features: [],
        likes: 38,
        createdAt: "2022-06-08 17:00:00",
        videoSrc: null
    },
    {
        id: 13,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/cryptoPunk/FestVibes.mp3",
        name: "Fest Vibes",
        price: '1.96',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/cryptoPunk/FestVibes.jpg",
        authorId: 2,
        authorName: "EDM punk",
        features: [],
        likes: 51,
        createdAt: "2022-06-08 17:00:00",
        videoSrc: null
    },
    {
        id: 14,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/cryptoPunk/GoaTranceMantra.mp3",
        name: "Goa Trance Mantra",
        price: '0.88',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/cryptoPunk/GoaTranceMantra.jpg",
        authorId: 2,
        authorName: "EDM punk",
        features: [],
        likes: 33,
        createdAt: "2022-06-08 17:00:00",
        videoSrc: null
    },
    {
        id: 15,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/cryptoPunk/InfectedVibes.mp3",
        name: "Infected Vibes",
        price: '1.2',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/cryptoPunk/InfectedVibes.jpg",
        authorId: 2,
        authorName: "EDM punk",
        features: [],
        likes: 18,
        createdAt: "2022-06-08 17:00:00",
        videoSrc: null
    },
    {
        id: 16,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/cryptoPunk/KeepingFit.mp3",
        name: "Keeping Fit",
        price: '1.23',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/cryptoPunk/KeepingFit.jpg",
        authorId: 2,
        authorName: "EDM punk",
        features: [],
        likes: 25,
        createdAt: "2022-06-08 17:00:00",
        videoSrc: null
    },
    {
        id: 17,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/rockPunk/ActionRock.mp3",
        name: "Action Rock",
        price: '1.23',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/rockPunk/AtionRock.gif",
        authorId: 3,
        authorName: "The punk Rocker",
        features: [],
        likes: 45,
        createdAt: "2022-06-10 17:00:00",
        videoSrc: null
    },
    {
        id: 18,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/rockPunk/CherryMental.mp3",
        name: "Cherry Mental",
        price: '2.23',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/rockPunk/CherryMEntal.gif",
        authorId: 3,
        authorName: "The punk Rocker",
        features: [],
        likes: 95,
        createdAt: "2022-06-10 17:00:00",
        videoSrc: null
    },
    {
        id: 19,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/rockPunk/Ending2.mp3",
        name: "Ending",
        price: '1.23',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/rockPunk/Ending2.gif",
        authorId: 3,
        authorName: "The punk Rocker",
        features: [],
        likes: 66,
        createdAt: "2022-06-10 17:00:00",
        videoSrc: null
    },
    {
        id: 21,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/rockPunk/TakeMeHigher.mp3",
        name: "Take me higher",
        price: '1.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/rockPunk/TakeMeHigher.gif",
        authorId: 3,
        authorName: "The punk Rocker",
        features: [],
        likes: 78,
        createdAt: "2022-06-10 17:00:00",
        videoSrc: null
    },
    {
        id: 22,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/djMike/caT8000.mp3",
        name: "T8000",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/djMike/T800.png",
        authorId: 4,
        authorName: "The punk Rocker",
        features: [],
        likes: 78,
        createdAt: "2022-06-12 17:00:00",
        videoSrc: null
    },
    {
        id: 23,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/djMike/Christofffrrr.mp3",
        name: "Christofffrrr",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/djMike/Christofffrrr.png",
        authorId: 4,
        authorName: "The punk Rocker",
        features: [],
        likes: 78,
        createdAt: "2022-06-12 17:00:00",
        videoSrc: null
    },
    {
        id: 24,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/djMike/FelinusJones.mp3",
        name: "Christofffrrr",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/djMike/FelinusJones.png",
        authorId: 4,
        authorName: "The punk Rocker",
        features: [],
        likes: 78,
        createdAt: "2022-06-12 17:00:00",
        videoSrc: null
    },
    {
        id: 25,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/djMike/JackSmile.mp3",
        name: "Jack smile",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/djMike/FelinusJones.png",
        authorId: 4,
        authorName: "The punk Rocker",
        features: [],
        likes: 78,
        createdAt: "2022-06-12 17:00:00",
        videoSrc: null
    },
    {
        id: 26,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "https://openseauserdata.com/files/26789a718093a5a7048b7593396f6add.wa",
        name: "Love overflow",
        price: '2.6',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/patJunior/background.jpg",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 34,
        createdAt: "2022-06-15 17:00:00",
        videoSrc: null
    },
    // {
    //     id: 27,
    //     type: TYPES.SIGNLE,
    //     status: STATUSES.BUY_NOW,
    //     src: "/audio/cryptoRaiders/CryptOfDreams.wav",
    //     name: "Love overflow #1",
    //     price: '0.6',
    //     unitPrice: CURRENCIES.BNB,
    //     background: "/img/collections/patJunior/background.jpg",
    //     authorId: 5,
    //     authorName: "The punk Rocker",
    //     features: [],
    //     likes: 44,
    //     createdAt: "2022-06-15 17:00:00",
    //     videoSrc: null
    // },
    {
        id: 28,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/CoolWithYou.m4a",
        name: "Cool with you?",
        price: '2.6',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/patJunior/background1.jfif",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 44,
        createdAt: "2022-06-15 17:00:00",
        videoSrc: null
    },
    //LastCallAtTheDrunkenFairy
    {
        id: 29,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/FeelsLike.m4a",
        name: "Feels like",
        price: '1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/patJunior/background2.jfif",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 67,
        createdAt: "2022-06-15 17:00:00",
        videoSrc: null
    },
    {
        id: 30,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/Godly.m4a",
        name: "Godly",
        price: '1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/patJunior/background3.jfif",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 51,
        createdAt: "2022-06-15 17:00:00",
        videoSrc: null
    },
    {
        id: 31,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/GoldsIn.m4a",
        name: "Golds in",
        price: '1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/patJunior/background1.jfif",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 51,
        createdAt: "2022-06-15 17:00:00",
        videoSrc: null
    },
    {
        id: 32,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/RememberWhy.m4a",
        name: "Remember why",
        price: '0.8',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/patJunior/background2.jfif",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 41,
        createdAt: "2022-06-15 17:00:00",
        videoSrc: null
    },
    {
        id: 33,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/Rest!.m4a",
        name: "Rest!",
        price: '2',
        unitPrice: CURRENCIES.ETH,
        background: "/img/collections/patJunior/background3.jfif",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 134,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 34,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/ThisFire.m4a",
        name: "This fire",
        price: '2',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/patJunior/background2.jfif",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 134,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 134,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/ThisIsTheDay.m4a",
        name: "This is the day",
        price: '2',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/patJunior/background1.jfif",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 134,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 135,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/patJunior/YouWereNeverBroken.m4a",
        name: "You were never broken",
        price: '2',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/patJunior/background.jpg",
        authorId: 5,
        authorName: "The punk Rocker",
        features: [],
        likes: 134,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 35,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/CallMe.mp3",
        name: "Call me",
        price: '2',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeBoredMusicClub/CallMe.avif",
        authorId: 6,
        authorName: "The punk Rocker",
        features: [],
        likes: 99,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 36,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/Enigma.mp3",
        name: "Enigma",
        price: '1.1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeBoredMusicClub/Enigma.avif",
        authorId: 6,
        authorName: "The punk Rocker",
        features: [],
        likes: 100,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 37,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/Love.mp3",
        name: "Enigma",
        price: '1.1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeBoredMusicClub/Love.avif",
        authorId: 6,
        authorName: "The punk Rocker",
        features: [],
        likes: 67,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 38,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/Morning.mp3",
        name: "Morning",
        price: '1.4',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeBoredMusicClub/Morning.avif",
        authorId: 6,
        authorName: "The punk Rocker",
        features: [],
        likes: 79,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 39,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/PowerFullTrap.mp3",
        name: "Power full trap",
        price: '1.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeBoredMusicClub/PowerFullTrap.avif",
        authorId: 6,
        authorName: "The punk Rocker",
        features: [],
        likes: 93,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 40,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/Sweet.mp3",
        name: "Sweet",
        price: '1.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeBoredMusicClub/Sweet.avif",
        authorId: 6,
        authorName: "The punk Rocker",
        features: [],
        likes: 74,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 41,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/TakeIt.mp3",
        name: "Take it",
        price: '1.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeBoredMusicClub/TakeIt.avif",
        authorId: 6,
        authorName: "The punk Rocker",
        features: [],
        likes: 64,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 42,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/apeBoredMusicClub/Voices.mp3",
        name: "Voices",
        price: '1.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/apeBoredMusicClub/Voices.avif",
        authorId: 6,
        authorName: "The punk Rocker",
        features: [],
        likes: 55,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 43,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/asyncMusic/Async1.mp3",
        name: "AsyncMusic #0001",
        price: '1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/asyncMusic/AsyncMusic1.jpg",
        authorId: 7,
        authorName: "The punk Rocker",
        features: [],
        likes: 45,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 44,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/asyncMusic/Async2.mp3",
        name: "AsyncMusic #0002",
        price: '1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/asyncMusic/AsyncMusic1.jpg",
        authorId: 7,
        authorName: "The punk Rocker",
        features: [],
        likes: 45,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 45,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/asyncMusic/Async3.mp3",
        name: "AsyncMusic #0003",
        price: '1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/asyncMusic/AsyncMusic1.jpg",
        authorId: 7,
        authorName: "The punk Rocker",
        features: [],
        likes: 51,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 46,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/asyncMusic/Async4.mp3",
        name: "AsyncMusic #0004",
        price: '1',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/asyncMusic/AsyncMusic1.jpg",
        authorId: 7,
        authorName: "The punk Rocker",
        features: [],
        likes: 46,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 47,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/theRabbit/theRabit001.wav",
        name: "WVRP #001",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/theRabbit/theRabbit001.avif",
        authorId: 8,
        authorName: "The punk Rocker",
        features: [],
        likes: 31,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 48,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/theRabbit/theRabit002.wav",
        name: "WVRP #002",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/theRabbit/theRabbit002.avif",
        authorId: 8,
        authorName: "The punk Rocker",
        features: [],
        likes: 48,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 49,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/theRabbit/theRabit003.wav",
        name: "WVRP #003",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/theRabbit/theRabbit003.avif",
        authorId: 8,
        authorName: "The punk Rocker",
        features: [],
        likes: 11,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 50,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/theRabbit/theRabit004.wav",
        name: "WVRP #004",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/theRabbit/theRabbit004.avif",
        authorId: 8,
        authorName: "The punk Rocker",
        features: [],
        likes: 11,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 51,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/theRabbit/theRabit005.wav",
        name: "WVRP #005",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/theRabbit/theRabbit005.avif",
        authorId: 8,
        authorName: "The punk Rocker",
        features: [],
        likes: 11,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 52,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/theRabbit/theRabit006.wav",
        name: "WVRP #006",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/theRabbit/theRabbit006.avif",
        authorId: 8,
        authorName: "The punk Rocker",
        features: [],
        likes: 11,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 53,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/theRabbit/theRabit007.wav",
        name: "WVRP #007",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/theRabbit/theRabbit007.avif",
        authorId: 8,
        authorName: "The punk Rocker",
        features: [],
        likes: 11,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 54,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/dreamer/001.mp3",
        name: "Dreamer #001",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/dreamer/001.gif",
        authorId: 9,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 55,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/dreamer/002.mp3",
        name: "Dreamer #002",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/dreamer/002.gif",
        authorId: 9,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 56,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/dreamer/003.mp3",
        name: "Dreamer #003",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/dreamer/003.gif",
        authorId: 9,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 57,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/dreamer/004.mp3",
        name: "Dreamer #004",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/dreamer/004.gif",
        authorId: 9,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 58,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/dreamer/005.mp3",
        name: "Dreamer #005",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/dreamer/005.gif",
        authorId: 9,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 59,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/dreamer/006.mp3",
        name: "Dreamer #006",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/dreamer/006.gif",
        authorId: 9,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: null
    },
    {
        id: 60,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "",
        name: "Piggy #001",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/dreamer/006.gif",
        authorId: 10,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: "/videos/ravePigs/RavePigs001.mp4"
    },
    {
        id: 61,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "",
        name: "Piggy #002",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "",
        authorId: 10,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: "/videos/ravePigs/piggy002.mp4"
    },
    {
        id: 62,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "",
        name: "Piggy #003",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "",
        authorId: 10,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: "/videos/ravePigs/piggy003.mp4"
    },
    {
        id: 63,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "",
        name: "Piggy #004",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "",
        authorId: 10,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: "/videos/ravePigs/piggy004.mp4"
    },
    {
        id: 64,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "",
        name: "Piggy #005",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "",
        authorId: 10,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: "/videos/ravePigs/piggy005.mp4"
    },
    {
        id: 66,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "",
        name: "Piggy #006",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "",
        authorId: 10,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: "/videos/ravePigs/piggy006.mp4"
    },
    {
        id: 67,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "",
        name: "Piggy #007",
        price: '0.5',
        unitPrice: CURRENCIES.BNB,
        background: "",
        authorId: 10,
        authorName: "The punk Rocker",
        features: [],
        likes: 65,
        createdAt: "2022-06-18 17:00:00",
        videoSrc: "/videos/ravePigs/piggy007.mp4"
    },
    {
        id: 68,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/zah23/MenteOscura.mp3",
        name: "Mente oscura",
        price: '0.25',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/zah23/kolors.png",
        authorId: 11,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 69,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/zah23/Raise.mp3",
        name: "Mente oscura",
        price: '0.25',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/zah23/kolors.png",
        authorId: 11,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 70,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/zah23/SenzaITuoiOcchi.mp3",
        name: "Mente oscura",
        price: '0.25',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/zah23/kolors.png",
        authorId: 11,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 71,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/zah23/SimbiosiA.mp3",
        name: "Mente oscura",
        price: '0.25',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/zah23/kolors.png",
        authorId: 11,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 141,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/carla/Active.m4a",
        name: "Active",
        price: '3',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/carlaThePoet/active.jfif",
        authorId: 14,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 142,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/carla/Karma.m4a",
        name: "Karma",
        price: '4',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/carlaThePoet/Karma.jfif",
        authorId: 14,
        authorName: "The punk Rocker",
        features: [FEATURES.NEW],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 143,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/carla/Karma.m4a",
        name: "Quite strom hour",
        price: '0.25',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/carlaThePoet/quiteInHour.jfif",
        authorId: 14,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 144,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/carla/Insecure.m4a",
        name: "Insecure",
        price: '3.5',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/carlaThePoet/insecure.jfif",
        authorId: 14,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 145,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/carla/Legend.m4a",
        name: "Legend",
        price: '2.25',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/carlaThePoet/Legend.jfif",
        authorId: 14,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 151,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/soTuffSoCute/BreakStuff.m4a",
        name: "Break Stuff #6",
        price: '2.99',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/soTuffSoCute/BreakStuff.jfif",
        authorId: 15,
        authorName: "The punk Rocker",
        features: [FEATURES.NEW],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 152,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/soTuffSoCute/BreakStuff.m4a",
        name: "Break Stuff #6",
        price: '2.99',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/soTuffSoCute/BreakStuff.jfif",
        authorId: 15,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 153,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/soTuffSoCute/BreakStuff.m4a",
        name: "Break Stuff #6",
        price: '2.99',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/soTuffSoCute/BreakStuff.jfif",
        authorId: 15,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 154,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/soTuffSoCute/BreakStuff.m4a",
        name: "Break Stuff #6",
        price: '2.99',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/soTuffSoCute/BreakStuff.jfif",
        authorId: 15,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 155,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/soTuffSoCute/BreakStuff.m4a",
        name: "Break Stuff #6",
        price: '2.99',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/soTuffSoCute/BreakStuff.jfif",
        authorId: 15,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
    {
        id: 156,
        type: TYPES.SIGNLE,
        status: STATUSES.BUY_NOW,
        src: "/audio/soTuffSoCute/BreakStuff.m4a",
        name: "Break Stuff #6",
        price: '2.99',
        unitPrice: CURRENCIES.BNB,
        background: "/img/collections/soTuffSoCute/BreakStuff.jfif",
        authorId: 15,
        authorName: "The punk Rocker",
        features: [],
        likes: 37,
        createdAt: "2022-06-20 17:00:00",
        videoSrc: null
    },
];

export const DummyAuthors = [
    {
        id: 1,
        name: "Ape drops",
        twitter: "",
        avatar: "/img/author/apeDrops.png",
        followers: 101,
        background: "https://www.sound.xyz/_next/image?url=https%3A%2F%2Fd2i9ybouka0ieh.cloudfront.net%2Fuser-uploads%2F338df8f3-eb00-4779-9405-1c7fe2e045f0%2FAVATAR_IMAGE%2F4365422-ethanwhallowell.jpeg&w=1920&q=75"
    },
    {
        id: 2,
        name: "EDM punk",
        twitter: "",
        avatar: "https://www.sound.xyz/_next/image?url=https%3A%2F%2Fd2i9ybouka0ieh.cloudfront.net%2Fuser-uploads%2F1b9d03c1-c12c-4c83-baa2-310b47fe015d%2FAVATAR_IMAGE%2F1121664-web3brett.jpeg&w=1920&q=75",
        followers: 96,
        background: "/img/collections/cryptoPunk/FestFeel.jpg"
    },
    {
        id: 3,
        name: "The punk Rocker",
        twitter: "",
        avatar: "/img/author/ThePunkRocker.jpg",
        followers: 12,
        background: "/img/collections/rockPunk/AtionRock.gif"
    },
    {
        id: 4,
        name: "DjMike",
        twitter: "",
        avatar: "/img/author/djMike.jpg",
        followers: 78,
        background: "/img/collections/djMike/FelinusJones.png"
    },
    {
        id: 5,
        name: "Pat junior",
        twitter: "",
        avatar: "/img/author/patJunior.webp",
        followers: 1000,
        background: "https://pbs.twimg.com/profile_banners/420013363/1655734370/1500x500",
        twitter: {
            link: "https://twitter.com/iampatjuni0r",
            name: "@iampatjuni0r"
        },
        sportify: {
            link: "https://open.spotify.com/artist/0lOsH3CaZpQIE3QO40SFvx?si=Wf3_KnyHSMSFXLnKVJP7CQ&nd=1",
            name: "pat junior"
        }
    },
    {
        id: 6,
        name: "Bored ape music club",
        twitter: "",
        avatar: "/img/author/boredApeMusicClub.avif",
        followers: 135,
        background: "/img/collections/apeBoredMusicClub/Enigma.avif"
    },
    {
        id: 7,
        name: "Async music",
        twitter: "",
        avatar: "/img/author/asyncMusic.png",
        followers: 99,
        background: "/img/collections/asyncMusic/AsyncMusic1.jpg"
    },
    {
        id: 8,
        name: "The rabbit",
        twitter: "",
        avatar: "/img/author/theRabbit.avif",
        followers: 35,
        background: "/img/collections/theRabbit/theRabbit002.avif"
    },
    {
        id: 9,
        name: "Devin Tracy",
        twitter: "",
        avatar: "/img/author/DevinTracy.jpg",
        followers: 35,
        background: "/img/collections/dreamer/002.gif"
    },
    {
        id: 10,
        name: "Piggy",
        twitter: "",
        avatar: "/img/author/RavePigs.jpg",
        followers: 66,
        background: "/img/collections/dreamer/006.gif"
    },
    {
        id: 11,
        name: "Zah2.3",
        twitter: "",
        avatar: "/img/author/zah23.jpg",
        followers: 33,
        background: "/img/collections/zah23/kolors.png"
    },
    {
        id: 12,
        name: "Bored brother",
        twitter: {
            link: "https://twitter.com/boredbr0thers_",
            name: "@boredbrothers"
        },
        sportify: {
            // link: "https://twitter.com/boredbrothers_",
            // name: "Bored Brother"
        },
        avatar: "https://pbs.twimg.com/profile_images/1499549454357118976/ts3qtwqn_400x400.jpg",
        followers: 256,
        background: "https://pbs.twimg.com/profile_banners/1488234966047764480/1654883503/1500x500"
    },
    {
        id: 13,
        name: "Iman Europe",
        twitter: "",
        avatar: "https://lh3.googleusercontent.com/RCAz0-Qf8gS8otL-KMZxydgRr6Nm3FD4MqOd7rlnuZFE0xBhTGhH26hSK5O3kJQ4RGYp0WkUCC5zZpNiZH0B9vt79mE-KIBOQzaW=s168",
        followers: 256,
        twitter: {
            link: "https://twitter.com/boredbr0thers_",
            name: "@boredbrothers"
        },
        sportify: {
            // link: "https://twitter.com/boredbrothers_",
            // name: "Bored Brother"
        },
        background: "https://pbs.twimg.com/profile_banners/1488234966047764480/1654883503/1500x500"
    },
    {
        id: 14,
        name: "Carla the poet",
        twitter: {
            link: "https://twitter.com/carIathepoet",
            name: "@CarlaThePoet"
        },
        sportify: {
            link: "https://open.spotify.com/artist/22cqc02gHQkxOfffYswvIt",
            name: "Carla the Poet"
        },
        avatar: "/img/author/CarlaThePoet.jpg",
        followers: 256,
        background: "/img/collections/carlaThePoet/background.jfif"
    },
    {
        id: 15,
        name: "SoTuffSoCute",
        twitter: {
            link: "https://twitter.com/SoTuffS0Cute",
            name: "@SoTuffSoCute"
        },
        sportify: {
            link: "https://open.spotify.com/artist/1PaF0WJCG5MLAoOv5xSWeR",
            name: "SoTuffSoCute"
        },
        avatar: "/img/author/SoTuffSoCute.jfif",
        followers: 256,
        background: "/img/collections/soTuffSoCute/background.webp"
    }
];

export const topAuthorIds = [14, 15, 12, 13, 5, 1, 2]