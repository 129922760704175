const images = [
  {
    src: "/img/gallery/1.jpg",
    alt: "Gallery Title",
    caption: "Gallery Title",
    width: 416,
    height: 294
  },
  {
    src:
      "/img/gallery/2.jpg",
    alt: "Gallery Title",
    caption: "Gallery Title",
    width: 416,
    height: 294
  },
  {
    src:
      "/img/gallery/3.jpg",
    alt: "Gallery Title",
    caption: "Gallery Title",
    width: 416,
    height: 612
  },
  {
    src:
      "/img/gallery/4.jpg",
    alt: "Gallery Title",
    caption: "Gallery Show",
    width: 416,
    height: 294
  },
  {
    src: "/img/gallery/5.jpg",
    alt: "Gallery Title",
    caption: "Gallery Title",
    width: 416,
    height: 294
  },
  {
    src:
      "/img/gallery/6.jpg",
    alt: "Gallery Title",
    caption: "Gallery Title",
    width: 416,
    height: 294
  },
  {
    src:
      "/img/gallery/7.jpg",
    alt: "Gallery Title",
    caption: "Gallery Title",
    width: 416,
    height: 294
  },
  {
    src: "/img/gallery/1.jpg",
    alt: "Gallery Title",
    caption: "Gallery Title",
    width: 416,
    height: 294
  }
];
export default images;